/**
 * @author ayou[beica1@outook.com]
 * @date 2022/1/13 17:42
 * @description
 *   prime.ts of WeTrade
 */
import useRequest from '@/hooks/useRequest'
import { refreshPrime } from '@/modules/app/app'
import { subscribe, unsubscribe } from '@/pages/prime/prime.api'
import state, { PrimeDescriber } from '@/state'
import { computed, shallowRef } from 'vue'
import { YesOrNo, Data } from '@/types'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import { openDialog } from '@/components/popup/popup'

/**
 * 维护prime信息
 */
export const usePrime = () => {
  const subscribed = shallowRef<PrimeDescriber>(state.prime)
  const [_read, reading] = useRequest(refreshPrime)
  const [_subscribe, subscribing] = useRequest(subscribe)
  const [_unsubscribe, unsubscribing] = useRequest(unsubscribe)
  const loading = computed(() => reading.value || subscribing.value || unsubscribing.value)

  const read = () => _read().then(resp => {
    subscribed.value = resp
  })

  const toggle = () => {
    if (subscribed.value) {
      if (subscribed.value.renew) {
        _unsubscribe().then(read)
      } else {
        _subscribe().then(read)
      }
    }
  }

  // R.when(isLogged, read, true)

  return { loading, subscribed, toggle, read }
}

export const checkPrime = (data: Data, cb: Fn) => {
  const isPrime = computed(() => state.prime.member === YesOrNo.YES)
  if (isPrime.value) {
    return cb()
  } else {
    openDialog(LimitDialogTemplate, data)
  }
}
