
import { defineComponent } from 'vue'
import { isLogged } from '@/state'
import router from '@/router'

export default defineComponent(
  {
    name: 'Private',
    props: {
      tag: {
        type: String,
        default: 'span',
      },
    },
    setup () {
      const redirect = (e: Event) => {
        if (!isLogged()) {
          router.push('/loginSwich/0')
          e.stopPropagation()
        }
      }

      return {
        redirect,
      }
    },
  },
)
