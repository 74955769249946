
import Button from '@/components/Button.vue'
import Pic from '@/components/Pic.vue'
import { openDialog } from '@/components/popup/popup'
import TheConfirmDialog from '@/pages/prime/components/TheConfirmDialog.vue'
import TheHeaderForDetail from '@/pages/prime/components/TheHeader.detail.vue'
import { usePrime } from '@/pages/prime/prime'
import OnlyGroupA from '@/provider/OnlyGroupA.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Private from '@/provider/Private.vue'
import { isLogged } from '@/state'
import { YesOrNo } from '@/types'
import { computed, defineComponent } from 'vue'
import * as R from 'ramda'

const paragraphs = [
  // {
  //   img: 'prime/quick',
  //   title: 'prime_29',
  //   desc: 'prime_30',
  //   groupAOnly: true,
  // },
  {
    img: 'prime/live',
    title: 'prime_8',
    desc: 'prime_9',
  },
  // {
  //   img: 'prime/copy',
  //   title: 'prime_10',
  //   desc: 'prime_11',
  // },
  {
    img: 'prime/technical_icon',
    title: 'prime_32',
    desc: 'prime_33',
  },
  {
    img: 'prime/study',
    title: 'prime_12',
    desc: 'prime_13',
  },
  {
    img: 'prime/cup',
    title: 'prime_14',
    desc: 'prime_15',
  },
  {
    img: 'prime/coupon',
    title: 'prime_16',
    desc: 'prime_17',
  },
  {
    img: 'prime/discount',
    title: 'prime_18',
    desc: 'prime_19',
  },
]

export default defineComponent({
  name: 'Detail',
  components: {
    OnlyGroupA,
    Private,
    Button,
    Pic,
    PageWithHeader,
    TheHeaderForDetail,
  },
  setup () {
    const {
      subscribed,
      read,
      toggle,
      ...rest
    } = usePrime()
    const hasRenew = computed(() => subscribed.value?.renew === YesOrNo.YES)

    R.when(isLogged, read, true)

    return {
      ...rest,
      paragraphs,
      subscribed,
      hasRenew,
      toggle () {
        openDialog(TheConfirmDialog, {
          hasRenew: hasRenew.value,
          onConfirm: toggle,
        })
      },
    }
  },
})
