/**
* @author ayou[beica1@outook.com]
* @date 2022/1/12 15:43
* @description
*   Detail.vue of WeTrade
*/
<template>
<PageWithHeader header-class="bg-prime-title">
  <template #title>Prime</template>
  <TheHeaderForDetail :subscribed="subscribed" />
  <div class="px-16">
    <OnlyGroupA custom #="{ hint }">
      <template
        v-for="p in paragraphs" :key="p.title"
      >
        <div
          v-if="!(!hint && p.groupAOnly)"
          class="df-middle paragraph br-lg mb-8 px-16 py-12"
        >
          <Pic :src="p.img" width="56" height="56" />
          <div class="flex-1 ml-16">
            <t as="p" :path="p.title" class="f-bold mb-4">{{p.title}}</t>
            <t as="p" :path="p.desc" class="f-sm">{{p.desc}}</t>
          </div>
        </div>
      </template>
    </OnlyGroupA>
  </div>
  <template #footer>
    <Private>
      <Button
        class="f-bold f-lg block" :class="{primary: !hasRenew}"
        :progress="loading" @click="toggle"
      >
        <t :path="hasRenew ? 'prime_21' : 'prime_20'"></t>
      </Button>
    </Private>
  </template>
</PageWithHeader>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Pic from '@/components/Pic.vue'
import { openDialog } from '@/components/popup/popup'
import TheConfirmDialog from '@/pages/prime/components/TheConfirmDialog.vue'
import TheHeaderForDetail from '@/pages/prime/components/TheHeader.detail.vue'
import { usePrime } from '@/pages/prime/prime'
import OnlyGroupA from '@/provider/OnlyGroupA.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Private from '@/provider/Private.vue'
import { isLogged } from '@/state'
import { YesOrNo } from '@/types'
import { computed, defineComponent } from 'vue'
import * as R from 'ramda'

const paragraphs = [
  // {
  //   img: 'prime/quick',
  //   title: 'prime_29',
  //   desc: 'prime_30',
  //   groupAOnly: true,
  // },
  {
    img: 'prime/live',
    title: 'prime_8',
    desc: 'prime_9',
  },
  // {
  //   img: 'prime/copy',
  //   title: 'prime_10',
  //   desc: 'prime_11',
  // },
  {
    img: 'prime/technical_icon',
    title: 'prime_32',
    desc: 'prime_33',
  },
  {
    img: 'prime/study',
    title: 'prime_12',
    desc: 'prime_13',
  },
  {
    img: 'prime/cup',
    title: 'prime_14',
    desc: 'prime_15',
  },
  {
    img: 'prime/coupon',
    title: 'prime_16',
    desc: 'prime_17',
  },
  {
    img: 'prime/discount',
    title: 'prime_18',
    desc: 'prime_19',
  },
]

export default defineComponent({
  name: 'Detail',
  components: {
    OnlyGroupA,
    Private,
    Button,
    Pic,
    PageWithHeader,
    TheHeaderForDetail,
  },
  setup () {
    const {
      subscribed,
      read,
      toggle,
      ...rest
    } = usePrime()
    const hasRenew = computed(() => subscribed.value?.renew === YesOrNo.YES)

    R.when(isLogged, read, true)

    return {
      ...rest,
      paragraphs,
      subscribed,
      hasRenew,
      toggle () {
        openDialog(TheConfirmDialog, {
          hasRenew: hasRenew.value,
          onConfirm: toggle,
        })
      },
    }
  },
})
</script>

<style scoped lang="scss">
.paragraph {
  border: 1px solid var(--color-border);

  &:first-child {
    margin-top: 16px;
  }
}

</style>
