/**
* @author ayou[beica1@outook.com]
* @date 2022/1/12 18:32
* @description
*   TheHeader.detail.vue of WeTrade
*/
<template>
<TheVBlock>
  <div class="header br-lg df-middle">
    <div class="f-sm">
      <template v-if="isMember">
        <t
          path="prime_5" as="p" class="gold mb-4"
          :args="{startdate: subscribed.startIssue, enddate: subscribed.endIssue}"
        >
          From {{subscribed.startIssue}} to {{subscribed.endIssue}}
        </t>
        <t v-if="hasRenew" as="p" path="prime_6" :args="{date: subscribed.renewIssue}">
          Auto renewal on {{subscribed.renewIssue}}
        </t>
        <t pat="prime_7" as="p" v-else>Unsubscribed</t>
      </template>
      <template v-else>
        <money :v="subscribed.price" class="gold f-xl f-bold" />
        <t path="prime_4" class="ml-4">per month</t>
      </template>
    </div>
  </div>
</TheVBlock>
</template>

<script lang="ts">
import TheVBlock from '@/pages/prime/components/TheVBlock.vue'
import { YesOrNo } from '@/types'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'TheHeaderForDetail',
  components: { TheVBlock },
  props: {
    subscribed: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const isMember = computed(() => props.subscribed?.member === YesOrNo.YES)
    const hasRenew = computed(() => props.subscribed?.renew === YesOrNo.YES)

    return {
      isMember,
      hasRenew,
    }
  },
})
</script>

<style scoped lang="scss">
.header {
  overflow: hidden;
  position: relative;
  margin: 16px 32px 32px;
  padding-left: 88px;
  min-height: 80px;
  color: #D3CFC8;
  background-image: linear-gradient(90deg, #353E4B 0%, var(--color-black) 100%);
  box-shadow: 0 30px 30px -30px var(--color-black);

  &::before {
    position: absolute;
    top: 0;
    left: -2px;
    width: 80px;
    height: 100%;
    background: url(/img/prime/prime_badge@2x.png) center left no-repeat;
    background-size: 100% 100%;
    content: '';
  }

  &::after {
    position: absolute;
    top: 0;
    right: 6px;
    width: 71px;
    bottom: 0;
    background: url(/img/prime/star@2x.png) top right no-repeat;
    background-size: 100%;
    content: '';
  }
}

.gold {
  color: #ECD09A;
}
</style>
