/**
* @author 贝才[beica1@outook.com]
* @date 2021/1/4
* @description
*   Private.vue of FastTradeV3
*/
<template>
  <component :is="tag" @click.capture="redirect">
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { isLogged } from '@/state'
import router from '@/router'

export default defineComponent(
  {
    name: 'Private',
    props: {
      tag: {
        type: String,
        default: 'span',
      },
    },
    setup () {
      const redirect = (e: Event) => {
        if (!isLogged()) {
          router.push('/loginSwich/0')
          e.stopPropagation()
        }
      }

      return {
        redirect,
      }
    },
  },
)
</script>
