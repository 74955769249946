/**
* @author ayou[beica1@outook.com]
* @date 2022/1/14 17:01
* @description
*   TheConfirmDialog.vue of WeTrade
*/
<template>
<DialogTemplate>
  <template #title>
    <t path="prime_22">Subscription confirmation</t>
  </template>
  <div class="mb-32 px-32">
    <div class="t-center">
      <Pic :src="hasRenew ? 'prime/prime_off' : 'prime/prime_on'" width="88" height="88" />
    </div>
    <t :path="hasRenew ? 'prime_26': 'prime_23'" as="p"></t>
  </div>
</DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheConfirmDialog',
  components: { Pic, DialogTemplate },
  props: {
    hasRenew: Boolean,
  },
})
</script>

<style scoped>

</style>
