
import TheVBlock from '@/pages/prime/components/TheVBlock.vue'
import { YesOrNo } from '@/types'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'TheHeaderForDetail',
  components: { TheVBlock },
  props: {
    subscribed: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const isMember = computed(() => props.subscribed?.member === YesOrNo.YES)
    const hasRenew = computed(() => props.subscribed?.renew === YesOrNo.YES)

    return {
      isMember,
      hasRenew,
    }
  },
})
